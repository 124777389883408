@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
@use "../base/font" as *;
@use "colors" as *;

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

.content-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  mat-form-field.mat-form-field.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          width: auto;
          gap: 5px;
        }
      }
    }
  }

  .col2 {
    margin-bottom: 20px;
  }
}

.gg-map {
  .map-container {
    border-radius: 15px;
    margin-top: 10px;
  }
}

.farmland-map {
  .map-container {
    border-radius: 15px;
  }
}

.danger {
  color: $color-danger;
}

body {
  margin: 0;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
  font-family: Open Sans, Khmer;
}

@font-face {
  font-family: "Khmer";
  src: url("/assets/fonts/KHMEROSBATTAMBANG.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.view-icon-action {
  .selected {
    mat-icon.mat-icon {
      path {
        fill: $color-secondary;
      }
    }
  }
}

.note-text {
  color: $color-yellow;
  margin-bottom: 20px;
}

.caption-x-sm {
  color: $color-gray;
  font-size: $fs-x-small;
}

.caption-sm {
  color: $color-gray;
  font-size: $fs-small;
}

.caption-md {
  color: $color-gray;
  font-size: $fs-normal;
}

.pagination {
  width: 100%;
  margin-top: 50px;

  .mat-select-arrow,
  .mat-select-value {
    color: #ffffff;
  }
}

.header-action {

  button.btn-edit.mat-icon-button.mat-button-base,
  button.btn-delete.mat-icon-button.mat-button-base {
    @include border-radius(10px);
  }

  button.btn-edit {
    background: $color-yellow;

    &.mat-button-disabled {
      background-color: $disabled-text-color
    }
  }

  button.btn-delete {
    background: $color-danger;

    &.mat-button-disabled {
      background-color: $disabled-text-color
    }
  }

  button.btn-approve {
    background: $approve;
    color: #ffffff;
  }
}

mat-tab-group.mat-tab-group {
  height: 100%;

  .mat-tab-body-wrapper {
    flex: 1px;
  }
}

.app-loading {
  z-index: 1050;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #3333;
  top: 0;
  left: 0;
  @include background-image("/assets/svgs/loading.svg");
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline {
  border-top: 0;
  line-height: 2;
  padding: 8px 0;

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        top: 0;

        .mat-form-field-outline-start {
          border-radius: 12px 0 0 12px;
          min-width: 15px;
        }

        .mat-form-field-outline-end {
          border-radius: 0 12px 12px 0;
          min-width: 15px;
        }
      }

      .mat-form-field-outline.mat-form-field-outline-thick {
        color: $color-primary;
      }

      .mat-form-field-suffix {
        align-self: center;
      }

      .mat-form-field-prefix {
        top: 0;
        align-self: center;
        margin-right: 12px;

        .mat-icon {
          font-size: 100%;
        }
      }

      .mat-form-field-infix {
        border-top: 0;
        padding: 6px 0;
        width: 0px;
      }
    }

  }

  &.custom-picker {
    .mat-form-field-wrapper {
      // width: auto;
      // margin: 0;
      // padding: 0;

      .mat-form-field-flex {
        .mat-form-field-outline {
          top: 0;

          .mat-form-field-outline-start {
            border-radius: 5px 0 0 5px;
          }

          .mat-form-field-outline-end {
            border-radius: 0 5px 5px 0;
            min-width: 15px;
          }
        }

        .mat-form-field-infix {
          padding: 3px 0;
        }
      }

    }

    &.custom-year-picker {
      width: 75px;
      .mat-form-field-wrapper {
        width: 75px;
      }
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }

  &.mat-form-field-invalid.mat-form-field-invalid {
    .mat-form-field-outline.mat-form-field-outline-thick {
      color: $color-danger;
    }
  }
}

.custom-picker.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  line-height: 35px !important;
  height: 35px !important;
}

// ===style mat date range
mat-form-field.mat-form-field.mat-form-field.mat-form-field-appearance-outline.custom-date-range-picker {
  padding: 0;
  height: $btn-md-height;
  font-size: $fs-small;
  width: 230px;

  .mat-form-field-wrapper {
    margin: 0;

    .mat-form-field-flex {
      margin: 0;

      .mat-form-field-prefix {
        margin-right: 0;
        .mat-datepicker-toggle,
          .mat-datepicker-content .mat-calendar-next-button,
          .mat-datepicker-content .mat-calendar-previous-button {
            color: $color-secondary;
          }
      }

      .mat-form-field-infix {
        padding: 9px 0;
      }
    }
  }

}

//==  end style mat date range input

.mat-error,
.mat-hint {
  line-height: 16px;
}

.text-center {
  text-align: center;
}

.required::after {
  content: " *";
  color: $color-danger;
}

mat-error.mat-error {
  color: $color-danger;
  line-height: normal;
}

img.avtar-icon {
  background-color: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-size: cover;
  object-fit: cover;
}

mat-icon.mat-icon.sidebar-icon-logout {
  svg.icon-logout path {
    fill: $color-gray;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-gray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-secondary;
  border-radius: 5px;
}

.data-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;

  img {
    width: 348px;
    height: auto;
  }

  span {
    font-weight: bold;
  }
}

.header-action {

  button.mat-stroked-button,
  button.mat-flat-button {
    .mat-button-wrapper {
      padding-right: 10px;
    }
  }
}

.color-cg {
  color: $color-green;
}

.color-cd {
  color: $color-danger;
}

.color-cy {
  color: $color-yellow;
}

/* disable label all select in filter province commune district village  */
.disabled-label {
  color: $medium-grey;
}

h3.sub-title-content {
  margin: 0 0 10px;
}

.custom-input {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid $color-medium-gray;

  input[type="text"] {
    width: 100%;
    outline: 0;
    border-width: 0;
    padding: 10px 0;
    font-size: $fs-small;
    background-color: transparent;
  }
}

.province-custom-select {
  .mat-select-value-text {
    // icon color secondary
    color: $color-secondary;
  }
}

button.btn-location {
  mat-icon.mat-icon {
    &.svg-path {
        &.svg-fill {
          path {
            fill: $color-secondary;
          }
        }

        &.svg-stroke {
          path {
            stroke: $color-secondary;
          }
        }
      }
  }
}
